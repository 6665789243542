import React from "react"
import { Container, Col, Row, Table, Image,Figure } from "react-bootstrap"
import { graphql } from "gatsby"
//import { Link } from "gatsby"
import {Typography,Paper,Grid} from '@material-ui/core'

import Layout from "../../components/layoutHome"

import SEO from "../../components/seo"
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  Title:{
          width:"100%",
          display:"flex",
          justifyContent:"Center",
          //margin:"30px",
          height:"30px",
          fontFamily: 'Montserrat',
          fontWeight: 'bold',
          background: "rgba(71,  0,  0, 0.8)",
          color:"white",
          fontSize:"28px",
          alignItems:"center"
        },
        SubTitle:{
          width:"100%",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          marginBottom:"10px",
          textAlign:"justify",
          background: "rgba(71,  0,  0, 0.8)",
          color:"white",
          
        },
        imgContainer:{
            padding:"5px",
            width:"100%",
            display:"flex",
            justifyContent:"center",
            //alignItems:"center",
            height:"100%",
            maxHeight:"100%",
            
  
        },
        imgText:{
            textAlign:"justify",
  
        },
        img:{
            //border:"black",
            //borderStyle:"groove"
        },
        GridContainer:{
            margin:"10px"
        },
        EventContainer:{
          margin:"10px",
          width:"100%"
        },
        TextEvent:{
          width:"100%",
          padding:"10px",
          textAlign:"justify"
        },
        Seccion:{
            marginTop:"10px",
            marginBottom:"10px",
            paddingLeft:"10px",
            paddingRight:"10px"
        },
        espacio:{
            padding:"5px"
        },
        full:{
            width:"100%"
        },
        Principal:{
          maxHeight:"550px",
          maxWidth:"100%",
          padding:"10px",
        },
        titlePage:{
          marginBottom:"10px"
        },
        full:{
          maxWidth:"100%",
          width:"100%"
        },
        values:{
          padding:"5px",
            //width:"100%",
            display:"flex",
            justifyContent:"center",
            //alignItems:"center",
            //height:"100%",
            maxHeight:"100%",
        },
        MarginTables:{
          marginBottom:"10px",
          marginTop:"10px"
        },
        Secction2:{
          width:"100%",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          marginBottom:"10px",
          textAlign:"justify",
          background: "rgba(71,  0,  0, 0.8)",
          color:"white",
          marginTop:20,
          marginBottom:20
        }
  
    }));
const TableElement = props => (
  <div
    style={{
      backgroundColor: "#ffaaaa",
      border: "2px solid black",
      marginTop: "8px",
      marginBottom: "8px",
      padding: "4px 4px 4px 4px",
    }}
  >
    <p>{props.text}</p>
  </div>
)

const Nosotros = ({ data }) => {
  const Model = data.ceformaPagesShow4.data.page.content
  const classes = useStyles(); 
  return (
    <Layout>
      <SEO title="Nexos Internacionales" />
      <Container fuid>
        
        
        <Typography variant="h5" className={classes.Secction2} >
          Nuestros Nexos Internacionales
        </Typography>

        {
        Model.Nexos.length > 0 ? (
          <Grid container 
          className={classes.full}
          >
        
          {Model.Nexos.map((Article , index) => {
          return (
            <Paper className={classes.EventContainer}> 
                  <React.Fragment>
                      <Typography variant="h5" className={classes.SubTitle} >
                      {Article.title}
                      </Typography>
                      <Image src={Article.imgPrincipal} rounded 
                      className={classes.Principal}
                      />
                     
                     <Typography className={classes.TextEvent}>
                     {Article.description}
                     </Typography>
                     
                     {
                    /*Article.imgs.length > 0 ? (
                    <Grid container 
                    className={classes.Seccion}
                    >
                    {Article.imgs.map(( img , index) => {
                    return (
                  <Grid iteam xs={12} sm={6} md={6} lg={4} xl={4} 
                  className={classes.espacio}
                  
                  >
                    <Paper className={classes.imgContainer}>
                     <Figure>
                        <Figure.Image
                            width={"100%"}
                            height={231}
                            alt={img.ImgDescripcion}
                            src={img.ImgUrl}
                            className={classes.img}
                        />
                        <Figure.Caption className={classes.imgText}>
                        {img.ImgDescripcion}
                        </Figure.Caption>
                    </Figure>
                    </Paper>
                  </Grid>      
                  );
                })}
        </Grid>
          ) : (
            <Paper className={classes.Table}>
          
          </Paper>
     )*/}
                  </React.Fragment>  
                  </Paper>       
                  );
                })}
             
        </Grid>
          ) : (
            <Paper className={classes.Table}>
          
          </Paper>
     )}

      </Container>
    </Layout>
  )
}

export default Nosotros

export const query = graphql`
  query WhoWeAreQuery2 {
    ceformaPagesShow4 {
      data {
        page {
          content {
            companies {
              Curso
              Empresa
              Capacitados
            }
            pageTitle
            activities {
              Actividad
              img
            }
            PrincipalImg
            Certification1
            Certification2
            directiveTable {
              Nombre
              Puesto
            }
            descriptiveText
            CertificationText
            ValuesDescription
              ValuesIMG
              Nexos {
                description
                imgPrincipal
                title
              }
          }
        }
      }
    }
  }
`
